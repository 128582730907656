import Image from 'next/image';

const Preloader = () => {
  return (
    <div className="fixed z-10 inset-0 bg-neutral-50 flex items-center justify-center">
      <Image
        priority
        className="w-28 h-28"
        width={400}
        height={400}
        src="/images/preloader.webp"
        alt=""
      />
    </div>
  );
};

export default Preloader;
